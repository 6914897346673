<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row align-items-center no-gutters">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <Left />
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side forgot-section">
            <div class="code-verification">
              <AuthTop class="ml-0" />
              <a style="margin-top: 37px;" @click="removeItemsFromStorage()">Logout</a>

            </div>
            <div class="sign-in-form forgot-form">
            <form
              id="code-form-wrapper"
              @keyup.enter="verifyEmailcode(0)"
              @submit.prevent
            >
              <h4>Email Verification</h4>
              <p
                v-if="errorMessage != null"
                class="tag-line"
                style="color: red"
              >
                {{ errorMessage }}
              </p>
              <p
                v-if="resendMessage != null"
                class="tag-line"
                style="color: green"
              >
                {{ resendMessage }}
              </p>
              <p
                v-else-if="resendMessage == null && errorMessage == null"
                class="tag-line"
              >
                Please check your email we have sent you a Verification code on
                your given email ( {{ user.email }} ).
              </p>

              <div class="form-group">
                <input
                  id="email"
                  class="form-control"
                  :class="{
                    'is-invalid': validationStatus($v.details.code),
                  }"
                  v-model.trim="$v.details.code.$model"
                  spellcheck="false"
                  autocomplete="off"
                  trim
                  required
                  type="text"
                  v-on:keyup="errorNull"
                />

                <span class="placeholder">Verification Code</span>
                <div v-if="!$v.details.code.required" class="invalid-tooltip">
                  Verification code is required
                </div>
                <div v-if="!$v.details.code.minLength" class="invalid-tooltip">
                  Please enter 6 digit code
                </div>
                <div v-if="!$v.details.code.maxLength" class="invalid-tooltip">
                  Please enter 6 digit code
                </div>
                <div v-if="!$v.details.code.integer" class="invalid-tooltip">
                  Code must be a number
                </div>
              </div>
              <div class="event-btns-wrapper">
                <button
                  v-if="this.isDisabled == false"
                  type="button"
                  class="btn btn-primary w-100"
                  @click="verifyEmailcode(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Verify Code</span>
                </button>
                <button
                  v-else-if="this.isDisabled == true"
                  type="button"
                  class="btn btn-primary-disabled w-100"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Verify Code</span>
                </button>
              </div>
            </form>
            <div class="account account-resend">
              <div v-if="timerCount > 0" class="copyrights">
                <p>You can try again After: {{ timerCount }} second(s).</p>
              </div>
              <div v-else-if="timerCount == 0">
                <a @click="reSend(0)" :disabled="isLoadingArrayResend[0]">
                  <div
                  v-if="isLoadingArrayResend[0]"
                  class="lds-dual-ring-media"
                  ></div>
                  <span v-else>Resend Code</span></a
                  >
                </div>
              </div>
            </div>
            <AuthBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Left from "../../components/AuthLeft/Left.vue";
import AuthTop from "../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../components/AuthLeft/AuthBottom.vue";

export default {
  name: "Verify",
  components: { Left, AuthTop, AuthBottom },
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      isLoadingArrayResend: [],
      details: {
        code: "",
        email: "",
      },
      resendMessage: null,
      timerCount: 60,
      isDisabled: true,
    };
  },
  validations: {
    details: {
      code: {
        required,
        integer,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["group"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("auth", ["founderPayment"]),
  },
  mounted() {
    document.getElementById("email").addEventListener("input", this.onPaste);
    this.$store.commit("setErrors", {});
    document.getElementById("code-form-wrapper").click();
  },

  methods: {
    ...mapActions("auth", ["verifyEmail"]),
    ...mapActions("auth", ["verifyEmailRegisterRequest"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    onPaste(event) {
      if (event.inputType === "insertFromPaste") {
        this.errorNull();
      }
    },
    verifyEmailcode: function (index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.email = localStorage.getItem("emailToVerify");
      this.verifyEmail(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          if (this.user.mobile_verification == 1) {
            localStorage.removeItem("emailToVerify");
            this.$router.push({ name: "RegisterMobile" });
          } else {
            if (localStorage.getItem("invite_count") == 0) {
              localStorage.removeItem("emailToVerify");
              if (this.founderPayment) {
                this.$router.push({ name: "FounderUserPayment" });
              } else {
                this.$router.push({ name: "Teams" });
              }
            } else {
              localStorage.removeItem("emailToVerify");
              this.$router.push({ name: "PendingInvite" });
            }
          }
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },
    reSend(index) {
      this.resendMessage = null;
      this.errorMessage = null;
      this.timerCount = 0;
      this.$set(this.isLoadingArrayResend, index, true);
      this.details.email = localStorage.getItem("emailToVerify");
      this.verifyEmailRegisterRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$set(this.isLoadingArrayResend, index, false);
          this.timerCount = 60;
        } else {
          setTimeout(() => {
            this.timerCount = 0;
            this.$set(this.isLoadingArrayResend, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    errorNull: function () {
      this.errorMessage = null;
      this.$nextTick(() => {
        if (this.$v.$invalid) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
      });
    },
    removeItemsFromStorage() {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("user_link");
      localStorage.removeItem("pro_status");
      localStorage.removeItem("pro_expiry");
      localStorage.removeItem("emailToVerify");
      localStorage.removeItem("linkUrl");
      localStorage.removeItem("notifyId");
      this.$router.push({ name: "Login" });
    },
    email: function () {
      alert();
      this.$router.push({ name: "Email" });
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
