<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#changeMobileNumber"
      ref="openModalChangeMobileNumberOnRegister"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="changeMobileNumber"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Change Mobile Number</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="clearNumber"
              ref="closeChangeMobileNumberOnRegister"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent @keyup.enter="changeMobileNumber(0)">
              <div class="d-md-flex align-items-center">
                <div class="phone-code">
                  <b-form-select v-model="code" :options="codeList">
                    <template #first> </template>
                  </b-form-select>
                </div>

                <div class="form-group ml-md-4 mt-3 mt-md-0 phone-number mb-0">
                  <input
                    id="mob_phone"
                    class="form-control mb-0"
                    v-model.trim="$v.mob.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.mob),
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Mobile Number</span>
                  <div v-if="!$v.mob.integer" class="invalid-tooltip">
                    Mobile number should be number.
                  </div>
                  <div v-else-if="!$v.mob.minLength" class="invalid-tooltip">
                    Minimum length should be 10.
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              block
              @click="changeMobileNumber(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, integer } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "ChangeMobileNumber",
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      code: localStorage.getItem("mobileCodeToVerify"),
      mob: localStorage.getItem("mobileToVerify"),
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
    };
  },
  components: {},
  validations: {
    mob: { integer, minLength: minLength(10) },
  },
  methods: {
    ...mapActions("auth", ["verifyMobileRegisterRequest"]),
    ...mapActions("auth", ["getUserData"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    clearNumber() {
      this.mob = localStorage.getItem("mobileToVerify");
    },
    changeMobileNumber: function (index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var input = document.getElementById("mob_phone").value;
      localStorage.setItem("mobileToVerify", input);
      localStorage.setItem("mobileCodeToVerify", this.code);
      var formData = new FormData();
      formData.append("phone", input);
      formData.append("mob_code", this.code);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "user/updateMobile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.getUserData();
            var formDataMob = new FormData();
            formDataMob.append("phone", localStorage.getItem("mobileToVerify"));
            formDataMob.append("mob_code", localStorage.getItem("mobileCodeToVerify"));
            this.verifyMobileRegisterRequest(formDataMob).then((response) => {
              if (response.data.statusCode == 200) {
                this.$set(this.isLoadingArray, index, false);
                var elem = this.$refs.closeChangeMobileNumberOnRegister;
                elem.click();
                this.mob = "";
                this.$root.$emit("resetTimer");
              } else {
                setTimeout(() => {
                  this.$set(this.isLoadingArray, index, false);
                  this.timerCount = 0;
                  this.errorMessage = response.data.message;
                }, 500);
              }
            });
          } else {
            this.$alert(response.data.message);
            let elem = this.$refs.closeChangeMobileNumberOnRegister;
            elem.click();
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    errorNull: function () {
      this.errorMessage = null;
      if (this.mob == "" || this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    this.$root.$on("openChangeMobileNumberOnRegister", () => {
      var elem = this.$refs.openModalChangeMobileNumberOnRegister;
      elem.click();
      this.isDisabled = true;
    });
  },
};
</script>

<style></style>
