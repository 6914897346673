<template>
  <div>
    <ChangeMobileNumber />
    <div class="auth-wrapper overflow-hidden">
      <div class="container-fluid no-pdd">
        <div class="row align-items-center no-gutters">
          <div class="col-lg-6 d-none d-lg-block no-pdd">
            <Left />
          </div>
          <div class="col-lg-6 no-pdd">
            <div class="auth-side forgot-section">
              <div class="code-verification">
                <AuthTop class="ml-0" />
                <a style="margin-top: 37px;" @click="removeItemsFromStorage()">Logout</a>

              </div>
              <div class="sign-in-form forgot-form">
              <form
                
                id="code-form-wrapper"
                @keyup.enter="verifyMobilecode(0)"
                @submit.prevent
              >
                <h4>Mobile Number Verification</h4>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p
                  v-if="resendMessage != null"
                  class="tag-line"
                  style="color: green"
                >
                  {{ resendMessage }}
                </p>
                <p
                  v-else-if="resendMessage == null && errorMessage == null"
                  class="tag-line"
                >
                  Please check your mobile we have sent you a Verification code
                  on your given mobile number
                  <span v-if="user">
                    ( {{ user.mob_code }}{{ user.mob }} ).</span
                  >
                </p>

                <div class="form-group">
                  <input
                    id="email"
                    class="form-control"
                    :class="{
                      'is-invalid': validationStatus($v.details.code),
                    }"
                    v-model.trim="$v.details.code.$model"
                    spellcheck="false"
                    autocomplete="off"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Verification Code</span>
                  <div v-if="!$v.details.code.required" class="invalid-tooltip">
                    Verification code is required
                  </div>
                  <div
                    v-if="!$v.details.code.minLength"
                    class="invalid-tooltip"
                  >
                    Please enter 6 digit code
                  </div>
                  <div
                    v-if="!$v.details.code.maxLength"
                    class="invalid-tooltip"
                  >
                    Please enter 6 digit code
                  </div>
                  <div v-if="!$v.details.code.integer" class="invalid-tooltip">
                    Code must be a number
                  </div>
                </div>

                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="verifyMobilecode(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Verify Code</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Verify Code</span>
                  </button>
                </div>
                <div>
                  <a @click="bypassMobileVerification()">Remind me later</a>
                  <a
                    class="float-right"
                    v-if="changeBtn"
                    @click="changeMobileNumber()"
                    >Change mobile number</a
                  >
                </div>
              </form>

              <div class="account account-resend">
                <div v-if="timerCountMobile > 0" class="copyrights">
                  <p>
                    You can try again After: {{ timerCountMobile }} second(s).
                  </p>
                </div>
                <div v-else-if="timerCountMobile == 0 || this.timerCountMobile < 0">
                  <a @click="reSend(0)" :disabled="isLoadingArrayResend[0]">
                    <div
                      v-if="isLoadingArrayResend[0]"
                      class="lds-dual-ring-media"
                    ></div>
                    <span v-else>Resend Code</span></a
                  >
                </div>
              </div>
            </div>
              <AuthBottom />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Left from "../../components/AuthLeft/Left.vue";
import AuthTop from "../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../components/AuthLeft/AuthBottom.vue";
import ChangeMobileNumber from "./ChangeMobileNumber.vue";

export default {
  name: "VerifyMobile",
  components: { Left, AuthTop, AuthBottom, ChangeMobileNumber },
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      isLoadingArrayResend: [],
      details: {
        code: "",
        mob: "",
      },
      resendMessage: null,
      timerCountMobile: 60,
      isDisabled: true,
      changeBtn: false,
      intervalId: null,
    };
  },
  validations: {
    details: {
      code: {
        required,
        integer,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["group"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("auth", ["founderPayment"]),
    isNegative() {
      return this.timerCountMobile < 0;
    },
  },

  methods: {
    ...mapActions("auth", ["verifyMobile"]),
    ...mapActions("auth", ["verifyMobileRegisterRequest"]),
    ...mapActions("auth", ["getFounderPayment"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    onPaste(event) {
      if (event.inputType === "insertFromPaste") {
        this.errorNull();
      }
    },
    verifyMobilecode: function (index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.mob =this.user.mob;
      this.verifyMobile(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          if (localStorage.getItem("invite_count") == 0) {
            localStorage.removeItem("mobileToVerify");
            localStorage.removeItem("emailToVerify");
              this.$router.push({ name: "Teams" });
            
          } else {
            localStorage.removeItem("mobileToVerify");
            localStorage.removeItem("emailToVerify");
              this.$router.push({ name: "PendingInvite" });
          }
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },
    reSend(index) {
      this.changeBtn = false;
      this.resendMessage = null;
      this.errorMessage = null;
      this.timerCountMobile = 0;
      this.$set(this.isLoadingArrayResend, index, true);
      var formData = new FormData();
      formData.append("phone", this.user.mob);
      formData.append("mob_code",this.user.mob_code);
      this.verifyMobileRegisterRequest(formData).then((response) => {
        if (response.data.statusCode == 200) {
          this.$set(this.isLoadingArrayResend, index, false);
          this.timerCountMobile = 60;
          this.getFounderPayment();
        } else {
          setTimeout(() => {
            this.changeBtn = true;
            this.timerCountMobile = 0;
            this.$set(this.isLoadingArrayResend, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    decrementCounter() {
      this.timerCountMobile--;
      if(this.timerCountMobile  == 0) {
        this.changeBtn = true;
      }
    },
    errorNull: function () {
      this.errorMessage = null;
      this.$nextTick(() => {
        if (this.$v.$invalid) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
      });
    },
    bypassMobileVerification() {
      localStorage.removeItem("mobileCodeToVerify");
      localStorage.removeItem("mobileToVerify");
       if (localStorage.getItem("invite_count") != 0) {
          this.$router.push({ name: "PendingInvite" });
        }
      else {
           this.$router.push({ name: "Teams" });
        }
    },
    removeItemsFromStorage() {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("user_link");
      localStorage.removeItem("pro_status");
      localStorage.removeItem("pro_expiry");
      localStorage.removeItem("emailToVerify");
      localStorage.removeItem("mobileToVerify");
      localStorage.removeItem("mobileCodeToVerify");
      localStorage.removeItem("linkUrl");
      localStorage.removeItem("notifyId");
      this.$router.push({ name: "Login" });
    },
    changeMobileNumber() {
      this.$root.$emit("openChangeMobileNumberOnRegister");
    },
    email: function () {
      alert();
      this.$router.push({ name: "Email" });
    },
  },

  created() {
    this.intervalId = setInterval(() => {
      this.decrementCounter();
    }, 1000); // Update every second
  },

  destroyed() {
    clearInterval(this.intervalId);
  },

  mounted() {
    document.getElementById("email").addEventListener("input", this.onPaste);
    this.$store.commit("setErrors", {});
    document.getElementById("code-form-wrapper").click();
    this.reSend(0);
    this.$root.$on("resetTimer", () => {
      this.timerCountMobile = 60;
    });
  },
  // watch: {
  //   timerCountMobile: {
  //     handler(value) {
  //       if (value > 0) {
  //         setTimeout(() => {
  //           this.timerCountMobile--;
  //         }, 1000);
  //         
  //       }
  //     },
  //     immediate: true, // This ensures the watcher is triggered upon creation
  //   },
  // },
};
</script>
